/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */

/* This file contains all the common utility functions */

import * as CONSTANTS from "app/AppConfig/constants";
import {
  FORCE_MENA_REGION,
  ENABLE_SUBSCRIPTION,
  FORCE_ALPHANUMERIC_PASSWORD
} from "app/AppConfig/features";
import oResourceBundle from "app/i18n/";
import { getUserLocale } from "get-user-locale";
import {
  fnSubscriptionEntitlement,
  fnUpdateUserSubscription,
  fnUpdateResumePagePath
} from "app/store/action/";
import * as actionTypes from "app/store/action/actions";
import { store } from "app/App";
import { toast } from "core/components/Toaster/";
import weyyak_without_ads from "app/resources/assets/thumbnail/without-Ads_ar_desktop.jpg";
import without_Ads_en from "app/resources/assets/thumbnail/without-Ads_en_desktop.jpg"
import bannerArLandscape from "app/resources/assets/thumbnail/without_Ads_ar_mobile.jpg";
import  bannerEnLandscape from "app/resources/assets/thumbnail/without_Ads_en_mobile.jpg"
import axios from "axios";

const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
const { getName } = require("country-list");
const CountryCodes = require("country-code-info");

let lastSubscriptionFetch = new Date();
lastSubscriptionFetch.setDate(lastSubscriptionFetch.getDate() - 1);

export function secondsToHms(seconds) {
  seconds = Number(seconds);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? "min " : "min ") : "";
  const sDisplay = h === 0 && s > 0 ? s + (s === 1 ? "sec" : "sec") : "";
  return hDisplay + mDisplay + sDisplay;
}

export function getLocale() {
  return getUserLocale();
}

export function getBillingText(billingFrequency, billingCycle) {
  switch (billingCycle) {
    case "DAY":
      return billingFrequency > 1 ? oResourceBundle.days : oResourceBundle.day;
    // case "MONTH":
    //   return billingFrequency > 1 ? oResourceBundle.days : oResourceBundle.month;
    //   break;
    default:
      return billingFrequency > 1 ? oResourceBundle.days : oResourceBundle.day;
  }
}

export function getBillingFrequency(billingFrequency) {
  switch (billingFrequency) {
    case CONSTANTS.MONTHLY_PLAN_DAYS:
      return "Monthly";
    case CONSTANTS.WEEKLY_PLAN_DAYS:
      return "Weekly";
    default:
      return "";
  }
}

export function getTrialBannerData(imageType, isMENARegion, locale) {
  let imageUrl;
  if (isMENARegion) {
    if (locale === "en") {
      switch (imageType) {
        case CONSTANTS.TRIAL_BANNER_LANDSCAPE:
          imageUrl = bannerEnLandscape; //trialBannerInsideEnLandscape;
          break;
        case CONSTANTS.TRIAL_BANNER_SQUARE:
          imageUrl = without_Ads_en;//trialBannerInsideEnSquare;
          break;

        default:
          imageUrl = CONSTANTS.DEFAULT_IMAGE_STATIC_PATH + '/resources/assets/thumbnail/subscription-banner-inside-en-square.jpg';//trialBannerInsideEnSquare;
      }
    } else {
      switch (imageType) {
        case CONSTANTS.TRIAL_BANNER_LANDSCAPE:
         
          imageUrl = bannerArLandscape
          break;
        case CONSTANTS.TRIAL_BANNER_SQUARE:
          imageUrl =weyyak_without_ads;///trialBannerInsideArSquare;
          break;
        
        default:
          imageUrl = CONSTANTS.DEFAULT_IMAGE_STATIC_PATH + '/resources/assets/thumbnail/subscription-banner-inside-ar-square.jpg';//trialBannerInsideArSquare;
      }
    }
  } else {
    if (locale === "en") {
      switch (imageType) {
        case CONSTANTS.TRIAL_BANNER_LANDSCAPE:
          imageUrl = CONSTANTS.DEFAULT_IMAGE_STATIC_PATH + '/resources/assets/thumbnail/subscription-banner-outside-en-landscape.jpg';//trialBannerOutsideEnLandscape;
          break;
        case CONSTANTS.TRIAL_BANNER_SQUARE:
          imageUrl = CONSTANTS.DEFAULT_IMAGE_STATIC_PATH + '/resources/assets/thumbnail/subscription-banner-outside-en-square.jpg';//trialBannerOutsideEnSquare;
          break;      
          
        default:
          imageUrl = CONSTANTS.DEFAULT_IMAGE_STATIC_PATH + '/resources/assets/thumbnail/subscription-banner-outside-en-square.jpg';//trialBannerOutsideEnSquare;
      }
    } else {
      switch (imageType) {
        case CONSTANTS.TRIAL_BANNER_LANDSCAPE:
          imageUrl = CONSTANTS.DEFAULT_IMAGE_STATIC_PATH + '/resources/assets/thumbnail/subscription-banner-outside-ar-landscape.jpg';//trialBannerOutsideArLandscape;
          break;
        case CONSTANTS.TRIAL_BANNER_SQUARE:
          imageUrl = CONSTANTS.DEFAULT_IMAGE_STATIC_PATH + '/resources/assets/thumbnail/subscription-banner-outside-ar-square.jpg';//trialBannerOutsideArSquare;
          break;       
         
        default:
          imageUrl = CONSTANTS.DEFAULT_IMAGE_STATIC_PATH + '/resources/assets/thumbnail/subscription-banner-outside-ar-square.jpg';//trialBannerOutsideArSquare;
      }
    }
  }
  const trialBanner = {
    content_type: CONSTANTS.SUBSCRIPTION_BANNER_CONTENT_TYPE,
    title: CONSTANTS.SUBSCRIPTION_BANNER_CONTENT_TYPE,
    id: CONSTANTS.SUBSCRIPTION_BANNER_CONTENT_TYPE,
    thumbnail: imageUrl,
    imagery: {
      thumbnail: imageUrl,
      mobile_img: imageUrl
    }
  };

  return trialBanner;
}

export function isGeoBlocked(countryCode = "") {
  return (
    CONSTANTS.GEO_BLOCK_COUNTRIES.indexOf(countryCode.toUpperCase()) !== -1
  );
}

export function getCountryName(countryCode) {
  return getName(countryCode);
}

export function getCountryFromDial(countryDialCode) {
  return CountryCodes.findCountry({ dial: countryDialCode });
}

export function getCountryFromCode(countryCode) {
  return CountryCodes.findCountry({ a2: countryCode });
}

export function getCountryFromFullName(countryName) {
  return CountryCodes.findCountry({ name: countryName });
}

export function getRawNumber(phoneNumber) {
  const regex = /[ ]|[-]/gi;
  return phoneNumber.replace(regex, "");
}

export function getCountryCodeFromNumber(number) {
  try {
    const phone = phoneUtil.parse(number);
    return "+" + phone.getCountryCode();
  } catch (ex) {
    return "";
  }
}

export async function redirectAfterLogin() {
  if (this.props.loginToHome) {
    this.props.history.push(`/${this.props.locale}`);
  } else {
    const isUserEntitled = await isUserSubscribed();
    let sResumePath = getCookie(CONSTANTS.COOKIE_GO_TO_SUBSCRIBE);
    deleteCookie(CONSTANTS.COOKIE_GO_TO_SUBSCRIBE);
    if (sResumePath) {
      if (isUserEntitled) {
        this.props.history.push(`/${this.props.locale}`);
      } else {
        this.props.history.push(`/${this.props.locale}/${CONSTANTS.PLANS_DESCRIPTION}`);
        return;
      }
    }
    sResumePath = getCookie(CONSTANTS.COOKIE_VIDEO_RESUME_OBJECT);
    try {
      deleteCookie(CONSTANTS.COOKIE_VIDEO_RESUME_OBJECT);
      if (sResumePath) {
        console.log("sResumepath working")
        const videoObject = JSON.parse(sResumePath);
        const sNextPath = getNavigationPathForPremiumContent(
          videoObject.premiumType,
          videoObject.rightsType,
          videoObject.locale,
          videoObject.videoPath,
        );
        sNextPath.then(sPath => {
          if (sPath) {
            fnUpdateResumePagePath(videoObject.videoPath);
            fnNavTo.call(this, sPath);
          }
        });
        return;
      }
    } catch (e) { }
    sResumePath = getCookie(CONSTANTS.RESUME_PATH_COOKIE_NAME);
    deleteCookie(CONSTANTS.RESUME_PATH_COOKIE_NAME);
    if (sResumePath) {
      this.props.history.push(sResumePath);
    } else {
      this.props.history.push(`/${this.props.locale}`);
    }
  }
}

export function getUserIdentifier() {
  const userDetails =
    getServerCookie(CONSTANTS.COOKIE_USER_OBJECT) !== null
      ? JSON.parse(getServerCookie(CONSTANTS.COOKIE_USER_OBJECT))
      : null;

  let userIdentifier = "";
  if (userDetails) {
    if (userDetails.firstName) {
      userIdentifier = userDetails.firstName;
      if (userDetails.lastName) {
        userIdentifier += " " + userDetails.lastName;
      }
    } else {
      if (userDetails.email) {
        userIdentifier = userDetails.email;
        let username = userIdentifier.split('@')[0];
        
      } else if (userDetails.phoneNumber) {
        userIdentifier = userDetails.phoneNumber;
      }
    }
  }
  return userIdentifier;
}
export function getAdType(podIndex) {
  switch (podIndex) {
    case -1:
      return CONSTANTS.AD_TYPE_POSTROLL;
    case 0:
      return CONSTANTS.AD_TYPE_PREROLL;
    default:
      return CONSTANTS.AD_TYPE_MIDROLL;
  }
}

//--------GDPR- Google Ads------------------
export function isGoogleAdsEnable() {
  let GDPR_DATA = getGDPRCookie('GDPR_Cookies')
  let COMMAND = GDPR_DATA ? GDPR_DATA.googleAds : true
  return COMMAND
}

export function IsCleverTapEnabled() {

  let GDPR_DATA = getGDPRCookie('GDPR_Cookies')

  let COMMAND = GDPR_DATA ? GDPR_DATA.cleverTap : true

  return COMMAND
}

export function fnCheckValidPassword(sPassword = "") {
  if (FORCE_ALPHANUMERIC_PASSWORD) {
    return (
      sPassword.length >= CONSTANTS.PASSWORD_VALIDATION_CONTENT_LENGTH &&
      containsAlphabets(sPassword) &&
      containsNumerals(sPassword)
    );
  }
  return sPassword.length >= CONSTANTS.PASSWORD_VALIDATION_CONTENT_LENGTH;
}

export function parseArabic(str) {
  return Number(
    str
      .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
        return d.charCodeAt(0) - 1632; // Convert Arabic numbers
      })
      .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) {
        return d.charCodeAt(0) - 1776; // Convert Persian numbers
      })
  );
}

export function isValidPhone(number, countryCode) {
  try {
    const phone = countryCode
      ? phoneUtil.parse(number, countryCode)
      : phoneUtil.parse(number);
    return number.length > 5 && phoneUtil.isValidNumber(phone);
  } catch (ex) {
    return false;
  }
}

export function isValidEmail(email = "") {
  const regex = CONSTANTS.EMAIL_VALIDATION_REGEX;
  return regex.test(email);
}
export function containsAlphabets(text) {
  const alpha = /[a-z]/i;
  return alpha.test(text);
}

export function containsNumerals(text) {
  const numeric = /\d/;
  return text.match(numeric) !== null;
}

export function extractNumber(text) {
  return text.replace(/\D/g, "");
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getFeaturePlayListType(sType) {
  if (!sType) sType = "";
  let sPlayListType = "";
  if (sType.toLowerCase().indexOf("layout a") > -1) {
    sPlayListType = "A";
  } else if (sType.toLowerCase().indexOf("layout b") > -1) {
    sPlayListType = "B";
  } else if (sType.toLowerCase().indexOf("layout c") > -1) {
    sPlayListType = "C";
  } else if (sType.toLowerCase().indexOf("layout d") > -1){
    sPlayListType = "D";
  } else {
    sPlayListType = "B";
  }
  return sPlayListType;
}

export function enterFullScreen(element) {
  if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
    return true;
  } else if (element.requestFullscreen) {
    element.requestFullscreen();
    return true;
  } else {
    return false;
  }
}

export function exitFullscreen() {
  if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
    return true;
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
    return true;
  } else {
    return false;
  }
}

export function isIE() {
  return (
    !!navigator.userAgent.match(/Trident/g) ||
    !!navigator.userAgent.match(/MSIE/g)
  );
}

export function getDirection(locale) {
  switch (locale) {
    case "ar":
    case "arc":
    case "dv":
    case "fa":
    case "ha":
    case "he":
    case "khw":
    case "ks":
    case "ku":
    case "ps":
    case "ur":
    case "yi":
      return "rtl";
    default:
      return "ltr";
  }
}

/**
 * Function to help override similar toasts
 */
export function showToast(id, message, position) {
  if (toast.isActive(id)) {
    toast.update(id, {
      render: message,
      toastId: id
    });
  } else {
    toast.success(message, {
      position: position,
      toastId: id
    });
  }
}


export function Encrypt(value) {
  var result = "";
  for (var i = 0; i < value.length; i++) {
    if (i < value.length - 1) {
      result += value.charCodeAt(i) + 10;
      result += "-";
    }
    else {
      result += value.charCodeAt(i) + 10;
    }
  }
  return result;
}
export function Decrypt(value) {
  var result = "";
  var array = value.split("-");

  for (var i = 0; i < array.length; i++) {
    result += String.fromCharCode(array[i] - 10);
  }
  return result;
}

export function setGenerealCookie(cname, cvalue, exptime) {

  let CookieInfo = JSON.stringify(cvalue)
  let evalue = Encrypt(CookieInfo);
  localStorage.setItem(cname, evalue)
}

export function getGeneralCookie(cname) {
  let getcname = localStorage.getItem(cname)
  let GDPRCookie = null

  if (getcname) {
    let deccookie = Decrypt(getcname);

    if (deccookie != undefined) {
      GDPRCookie = JSON.parse(deccookie)
    }
  }

  return GDPRCookie;
}

export function DeleteGeneralCookie(cname) {
  localStorage.removeItem(cname);
}



export function setGDPRCookie(cname, cvalue, exptime) {

  if (cname == 'GDPR_Cookies') {
    let CurrentData = new Date();
    CurrentData.setTime(CurrentData.getTime() + exptime);
    cvalue.expiresTime = CurrentData.getTime();
  }

  let CookieInfo = JSON.stringify(cvalue)
  let evalue = Encrypt(CookieInfo);
  localStorage.setItem(cname, evalue)
}

export function getGDPRCookie(cname) {
  let getcname = localStorage.getItem(cname)
  let GDPRCookie = null

  if (getcname) {
    let deccookie = Decrypt(getcname);

    if (deccookie != undefined) {
      GDPRCookie = JSON.parse(deccookie)
    }
  }

  return GDPRCookie;
}

export function DeleteGDPRCookie(cname) {
  localStorage.removeItem(cname);
}

export function setCookie(cname, cvalue, exptime) {
  var d = new Date();

  if (cname == 'COOKIE_USER_OBJECT' || cname == 'COOKIE_USER_TOKEN') {
    let usercookie = {
      cookiename: cname,
      cookievalue: encodeURIComponent(cvalue)
    };
    let evalue = Encrypt(cvalue);
    localStorage.setItem(cname, evalue)
    axios.post('/cookie', usercookie).then(function (response) {

    }).catch((err) => {
      console.log(err)
    })
  }
  d.setTime(d.getTime() + exptime);
  var expires = "expires=" + d.toUTCString();
  var ua = navigator.userAgent.toLowerCase();
  var userid = getUserId() ? getUserId() : uuidv4();
  if (ua.indexOf('safari') != -1) {
    if (ua.indexOf('chrome') > -1) {
      document.cookie = cname + "=" + encodeURIComponent(cvalue) + ";" + expires + ";path=/" + ";";
    } else {
      document.cookie = cname + "=" + encodeURIComponent(cvalue) + ";" + expires + ";path=/" + ";";

    }
  }

}


export function getServerCookie(cname) {
  var name = cname + "=";
  let getcname = localStorage.getItem(cname)

  if (getcname) {
    let deccookie = Decrypt(getcname);
    return deccookie;
  }
  else {
    return null
  }
  return null;
}

export function getGuestCookie(cname) {
  axios.get("/guestCookie", (req, res) => {

  })
}
export function getCookie(cname) {
  //COOKIE_USER_OBJECT
  //COOKIE_USER_TOKEN
  var name = cname + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return decodeURIComponent(c.substring(name.length, c.length));
    }
  }
  return null;
}
// }

export function deleteCookie(cname) {
  localStorage.removeItem(cname)
  document.cookie = cname + "=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";

  let usercookie = {
    cookiename: cname
  };
  if (cname == 'COOKIE_USER_OBJECT' || cname == 'COOKIE_USER_TOKEN') {
    axios.post('/destroyCookie', usercookie).then(function (response) {
    });
  }

}


export function SerializePostCall(oCredentials) {
  var encodedUrl = [];
  for (var key in oCredentials)
    if (oCredentials.hasOwnProperty(key)) {
      encodedUrl.push(
        encodeURIComponent(key) + "=" + encodeURIComponent(oCredentials[key])
      );
    }

  encodedUrl = encodedUrl.join("&");
  return encodedUrl;
}

export function creatingRequestBodySignIn(oRequest, oRequestType) {
  const commonRequestBodyDetails = {
    grant_type: oRequestType,
    deviceId: "web_app",
    deviceName: "web_app",
    devicePlatform: "web_app"
  };
  const requestBodyDetails = {
    ...commonRequestBodyDetails,
    ...(oRequest.username && { username: oRequest.username }),
    ...(oRequest.password && { password: oRequest.password }),
    ...(oRequest.accessToken && { token: oRequest.accessToken }),
    ...(oRequest.accessTokenSecret && {
      tokensecret: oRequest.accessTokenSecret
    })
  };

  return requestBodyDetails;
}

export function creatingRequestBodyForAppleSignIn(oRequest, oRequestType) {
  const commonRequestBodyDetails = {
    grant_type: oRequestType,
    deviceId: "web_app",
    deviceName: "web_app",
    devicePlatform: "web_app"
  };
  const requestBodyDetails = {
    ...commonRequestBodyDetails,
    ...(oRequest.user && { username: `${oRequest.user.name.firstName} ${oRequest.user.name.lastName}` }),
    ...(oRequest.user && { email: `${oRequest.user.email}` }),
    ...(oRequest.id_token && { token: oRequest.id_token }),
    ...(oRequest.id_token && { tokensecret: 'applesecret' }),
  };

  return requestBodyDetails;
}

export function saveUserDetails(_userObj, _userToken) {
  const cookiesTimeOut = CONSTANTS.COOKIES_TIMEOUT_REMEMBER;
  _userObj.languageName = "";
  if (_userObj) {
    setCookie(
      CONSTANTS.COOKIE_USER_OBJECT,
      JSON.stringify(_userObj),
      cookiesTimeOut
    );
  }
  if (_userToken) {
    setCookie(
      CONSTANTS.COOKIE_USER_TOKEN,
      JSON.stringify(_userToken),
      cookiesTimeOut
    );
  }
}

export function isTpay(paymentProvider, countryCode) {
  if (
    paymentProvider.toLowerCase() ===
    CONSTANTS.PAYMENT_OPERATOR_ETISALAT.toLowerCase() &&
    (countryCode === CONSTANTS.TPAY_ETISALAT_COUNTRY_CODE) === countryCode
  ) {
    return true;
  }
  return CONSTANTS.PAYMENT_OPERATORS_TPAY.find(
    ele => ele.toLowerCase() === paymentProvider.toLowerCase()
  );
}

export function getWatchingDate(date) {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getUTCFullYear();
  return month + "/" + day + "/" + (year - 2000);
}

export function creatingRequestBodySignUp(oRequest) {
  const requestBodyDetails = {
    firstName: oRequest.firstName,
    lastName: oRequest.lastName,
    password: oRequest.password,
    countryId: oRequest.countryId,
    Alpha2code: oRequest.Alpha2code,
    countryName: oRequest.countryName,
    languageId: oRequest.languageId,
    newsletterEnabled: oRequest.newsletterEnabled,
    privacyPolicy: oRequest.privacyPolicy,
    isAdult: oRequest.isAdult,
    IsRecommend: oRequest.IsRecommend,
    recaptcha_token:oRequest.recaptcha_token,
    DeviceId:'web',
    registrationPlatform : "web"
  };
  if (oRequest.email) {
    requestBodyDetails.email = oRequest.email;
  } else if (oRequest.phoneNumber) {
    requestBodyDetails.phonenumber = oRequest.phoneNumber;
  }
  return requestBodyDetails;
}

export function creatingUserObjectForCookies(oResponse) {
  const _userObj = {
    firstName: oResponse.firstName,
    lastName: oResponse.lastName,
    email: oResponse.email,
    newslettersEnabled: oResponse.newslettersEnabled,
    phoneNumber: oResponse.phoneNumber,
    countryId: oResponse.countryId,
    countryName: oResponse.countryName,
    languageId: oResponse.languageId,
    languageName: "",
    promotionsEnabled: oResponse.promotionsEnabled,
    privacyPolicy: oResponse.privacyPolicy,
    isAdult: oResponse.isAdult,
    advertising: oResponse.advertising,
    aique: oResponse.aique,
    appFlyer: oResponse.appFlyer,
    facebookAds: oResponse.facebookAds,
    firebase: oResponse.firebase,
    googleAds: oResponse.googleAds,
    googleAnalytics: oResponse.googleAnalytics,
    performance: oResponse.performance,
    user_count :oResponse.user_count,
    registrationPlatform : oResponse.registrationPlatform
  };
  return _userObj;
}

export function creatingUserObjectForFacebookCookies(oFacebookResponse) {
  const _userObj = {
    firstName: oFacebookResponse.name || "",
    email: oFacebookResponse.email || ""
  };
  return _userObj;
}

export function creatingUserObjectForAppleCookies(oAppleResponse) {
  const _userObj = {
    firstName: oAppleResponse.user != null ? oAppleResponse.user.name.firstName : "",
    email: oAppleResponse.user != null ? oAppleResponse.user.email : ""
  };
  return _userObj;
}

export function creatingUserTokenForCookies(oResponse) {
  const _userToken = {
    authToken: oResponse.access_token,
    refreshToken: oResponse.refresh_token,
    user_id: oResponse.user_id
  };
  store.dispatch({
    type: actionTypes.UPDATE_USER_TOKEN,
    payload: _userToken
  });
  return _userToken;
}

export function isUserLoggedIn() {
  const oUserToken = JSON.parse(getServerCookie(CONSTANTS.COOKIE_USER_TOKEN));
  const sAuthToken = oUserToken ? oUserToken.authToken : null;
  return sAuthToken ? true : false;
}

export function getUserId() {
  const oUserToken = getServerCookie(CONSTANTS.COOKIE_USER_TOKEN)
    ? JSON.parse(getServerCookie(CONSTANTS.COOKIE_USER_TOKEN))
    : null;

  return oUserToken ? oUserToken.user_id : null;
}

async function getGuestCookies() {
  return await axios.get("/getGuestCookieInfo/uuid").then(function (response) {
    return response;
  }).catch((err) => {
    console.log(err)
  })
}

export function uuidv4() {
  getGuestCookies().then(function (data) {
    if (data) {
      if (data.data && data.data == '1') {

        let uidValue = localStorage.getItem("uuid");
        if (uidValue == undefined || uidValue == null || uidValue == false) {
          uidValue = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          );
        }

        var now = new Date();
        now.setMonth(now.getMonth() + 12);
        document.cookie = "uuid" + "=" + uidValue + "; Path=/; expires=" + now.toUTCString() + ";";

        let usercookie = {
          cookiename: "uuid",
          cookievalue: uidValue
        };
        axios.post('/guestCookie', usercookie).then(function (response) {


        });
        return uidValue;
      } else {
        return data.data;
      }
    }

  });

}

export function getUid() {
  var udata = "";
  getGuestCookies().then(function (data) {
    if (data) {
      window.udata = data.data;
      localStorage.setItem("wdata", window.udata);
      return data.data;
    }
  });
  var wdata = window.udata;

  return wdata;
}



export function getUserEmail() {
  const oUserToken = getServerCookie(CONSTANTS.COOKIE_USER_OBJECT)
    ? JSON.parse(getServerCookie(CONSTANTS.COOKIE_USER_OBJECT))
    : null;
  return oUserToken ? oUserToken.email : null;
}

export function getUserPhone() {
  const oUserToken = getServerCookie(CONSTANTS.COOKIE_USER_OBJECT)
    ? JSON.parse(getServerCookie(CONSTANTS.COOKIE_USER_OBJECT))
    : null;
  return oUserToken ? oUserToken.phoneNumber : null;
}

export function getUserName() {
  const oUserToken = getServerCookie(CONSTANTS.COOKIE_USER_OBJECT)
    ? JSON.parse(getServerCookie(CONSTANTS.COOKIE_USER_OBJECT))
    : null;
  return oUserToken ? oUserToken.firstName + oUserToken.lastName : null;
}

export async function isUserSubscribed() {
  if (!ENABLE_SUBSCRIPTION) {
    return true;
  }
  const oUserToken = getServerCookie(CONSTANTS.COOKIE_USER_TOKEN)
    ? JSON.parse(getServerCookie(CONSTANTS.COOKIE_USER_TOKEN))
    : null;

  const sUserName = oUserToken ? oUserToken.user_id : "";
  let isUserEntitled = false;
  if (oUserToken) {
    const oSubscriptionData = await fnCheckUserEntitlement(
      sUserName,
      false,
      "en"
    );
    isUserEntitled =
      Array.isArray(oSubscriptionData) &&
        oSubscriptionData.length > 0 &&
        oSubscriptionData[0].state === "Active"
        ? true
        : false;
  }
  store.dispatch(fnUpdateUserSubscription(isUserEntitled));
  return isUserEntitled;
}

export async function UserSubscribedType() {
  if (!ENABLE_SUBSCRIPTION) {
    return "Subscribed_User";
  }

  const oUserToken = getServerCookie(CONSTANTS.COOKIE_USER_TOKEN)
    ? JSON.parse(getServerCookie(CONSTANTS.COOKIE_USER_TOKEN))
    : null;

  const sUserName = oUserToken ? oUserToken.user_id : "";
  let TypeOfUser = "Not_a_Subscribed_User";
  if (oUserToken) {
    const oSubscriptionData = await fnCheckUserEntitlement(
      sUserName,
      false,
      "en"
    );

    if(Array.isArray(oSubscriptionData) &&
    oSubscriptionData.length > 0 &&
    oSubscriptionData[0].state === "Active"){

      TypeOfUser = oSubscriptionData[0].subscription_plan.coupon_type ==  "Coupon" ? "Coupon_Subscribed_User" :
       oSubscriptionData[0].subscription_plan.coupon_type == "Voucher" ? "Discounted_Coupon_User" : "Subscribed_User"

    }else{
      TypeOfUser = "Not_a_Subscribed_User";
    }

  }
  return TypeOfUser;
}

export async function userSubscriptionPlan(includeAll, locale) {
  const oUserToken = getServerCookie(CONSTANTS.COOKIE_USER_TOKEN)
    ? JSON.parse(getServerCookie(CONSTANTS.COOKIE_USER_TOKEN))
    : null;

  const sUserName = oUserToken ? oUserToken.user_id : "";
  let userSubscriptionPlan = [];
  if (oUserToken) {
    const oSubscriptionData = await fnCheckUserEntitlement(
      sUserName,
      includeAll,
      locale
    );
    userSubscriptionPlan =
      Array.isArray(oSubscriptionData) && oSubscriptionData.length > 0
        ? oSubscriptionData
        : [];
  }
  return userSubscriptionPlan;
}

function fnCheckUserEntitlement(sUserName, includeAll, locale = "ar") {
  return new Promise((resolve, reject) => {
    store.dispatch(
      fnSubscriptionEntitlement(
        sUserName,
        includeAll === true ? true : false,
        locale,
        oResponse => {
          resolve(oResponse);
        },
        oError => {
          resolve(oError);
        }
      )
    );
  });
}
export function showSubscription(path) {
  if (path.includes(CONSTANTS.PLANS)) {
    return false;
  }
  if (path.includes(CONSTANTS.PAYMENT_ENTER_MOBILE)) {
    return false;
  }
  if (path.includes(CONSTANTS.PAYMENT_ENTER_OTP)) {
    return false;
  }
  if (path.includes("transactionstatus")) {
    return false;
  }
  if (path.includes("checkout")) {
    return false;
  }
  if (path.includes("adyen-enter-details")) {
    return false;
  }
  return true;
}

export async function getNavigationPathForPremiumContent(
  premium_type,
  rights_type,
  sLocale,
  sVideoPath,
  live_type,
) {
  if (!ENABLE_SUBSCRIPTION) {
    return sVideoPath;
  }
  const isMena = store.getState().isMENARegion;

  let sPath = "";
  const isUserEntitled = await isUserSubscribed();
  const loggedIn = isUserLoggedIn();
  if (!loggedIn && rights_type === 3) {
    sPath = `/${sLocale}/${CONSTANTS.LOGIN}`;
  }
  else if (!loggedIn && premium_type === "SVOD") {
    sPath = `/${sLocale}/${CONSTANTS.SUBSCRIPTION_TO_WATCH}`;
  }
  else if (rights_type !== undefined && loggedIn && !isUserEntitled) {
    if (rights_type === 3) {
      sPath = `/${sLocale}/${CONSTANTS.SUBSCRIPTION_TO_WATCH}`;
    }
    else if (premium_type == "AVOD" && rights_type === 1 && live_type !== "LiveTV") {
      sPath = sVideoPath
      // sPath = `/${sLocale}/${CONSTANTS.SUBSCRIPTION_TO_WATCH_AD}`;
    }
    else if (premium_type == "AVOD" && rights_type === 1 && live_type === "LiveTV") {
      sPath = sVideoPath
      // sPath = `/${sLocale}/${CONSTANTS.SUBSCRIPTION_TO_WATCH_AD}`;
    }
    else if (premium_type == "SVOD" && rights_type === 1) {
      sPath = `/${sLocale}/${CONSTANTS.SUBSCRIPTION_TO_WATCH}`;
    }
  } else if ((loggedIn && isUserEntitled)) {
    sPath = sVideoPath;
    // sPath = `/${sLocale}/${CONSTANTS.SUBSCRIPTION_TO_WATCH}`;
  }
  else if (!isUserEntitled && rights_type === 1) {
    sPath = sVideoPath

    // sPath = `/${sLocale}/${CONSTANTS.SUBSCRIPTION_TO_WATCH_AD}`;
  }
  return sPath;
}

export function fnNavTo(sPathName, bReplace = false) {
  if (this.props.location.pathname !== sPathName) {
    if (bReplace) {
      this.props.history.replace(sPathName);
    } else {
      this.props.history.push(sPathName);
    }
  }
}

export function fnConstructContentURL(type, ele) {
  let sURL = "";
  if (ele && type === CONSTANTS.EPISODE) {
    sURL = `/${ele.content_type}/${ele.id}/${ele.title.replace(/ +/g, "-")}-${ele.episode_number ? oResourceBundle.episode + ele.episode_number : ""
      }`;
  } else if (ele) {
    sURL = `/${ele.content_type}/${ele.id}/${ele.title.replace(/ +/g, "-")}`;
  }
  return sURL;
}

export function fnConstructTranslatedTitle(type, ele, locale, reverseLocale) {
  const oResourceBundleContent = oResourceBundle.getContent()[reverseLocale];
  let sURL = "";
  if (ele && type === CONSTANTS.EPISODE) {
    sURL = ele[reverseLocale + "title"]
      ? `${ele[reverseLocale + "title"].replace(/ +/g, "-")}-${ele.episode_number
        ? oResourceBundleContent.episode + ele.episode_number
        : ""
      }`
      : "";
  } else if (ele) {
    sURL = ele[reverseLocale + "title"]
      ? `${ele[reverseLocale + "title"].replace(/ +/g, "-")}`
      : "";
  }
  return sURL;
}

export function isMENARegion(sCountryCode = "") {
  if (!ENABLE_SUBSCRIPTION || FORCE_MENA_REGION) {
    return true;
  }
  const aListCountriesMENARegion = [
    "IQ",
    "JO",
    "KW",
    "LB",
    "OM",    
    "PS",
    "QA",
    "SA",
    "SY",
    "AE",
    "YE",
    "BH",
    "DZ",
    "EG",
    "LY",
    "MA",
    "SD",
    "SO",
    "DJ",
    "TN",
    // "ES",
    // "PH" ,
    "IN"
   
  ];
  const index = aListCountriesMENARegion.findIndex(
    ele => ele.toLowerCase() === sCountryCode.toLowerCase()
  );

  return index > -1 ? true : false;
}
export function isValidFullname(name=""){

  let truncated = name.trim();

  return truncated && name.length>5 && name.length<=60;

}
export function oneTierContentType(type) {

  if (type == "movie" || type == "livetv" || type == "play") {
    // console.log("Coming to content type function")
    return true;
  }
  else
    return false;

}
export function multiTierContentType(type) {

  if (type == "series" || type == "program") {
    // console.log("Coming to content type function")
    return true;
  }
  else
    return false;

}

export function defaultCountry(countrycode,lang){
  const countryJsonList= [
    {
    "name": "Afghanistan",
    "dial_code": "+93",
    "code": "AF"
    },
    {
    "name": "Aland Islands",
    "dial_code": "+358",
    "code": "AX"
    },
    {
    "name": "Albania",
    "dial_code": "+355",
    "code": "AL"
    },
    {
    "name": "Algeria",
    "dial_code": "+213",
    "code": "DZ"
    },
    {
    "name": "AmericanSamoa",
    "dial_code": "+1684",
    "code": "AS"
    },
    {
    "name": "Andorra",
    "dial_code": "+376",
    "code": "AD"
    },
    {
    "name": "Angola",
    "dial_code": "+244",
    "code": "AO"
    },
    {
    "name": "Anguilla",
    "dial_code": "+1264",
    "code": "AI"
    },
    {
    "name": "Antarctica",
    "dial_code": "+672",
    "code": "AQ"
    },
    {
    "name": "Antigua and Barbuda",
    "dial_code": "+1268",
    "code": "AG"
    },
    {
    "name": "Argentina",
    "dial_code": "+54",
    "code": "AR"
    },
    {
    "name": "Armenia",
    "dial_code": "+374",
    "code": "AM"
    },
    {
    "name": "Aruba",
    "dial_code": "+297",
    "code": "AW"
    },
    {
    "name": "Australia",
    "dial_code": "+61",
    "code": "AU"
    },
    {
    "name": "Austria",
    "dial_code": "+43",
    "code": "AT"
    },
    {
    "name": "Azerbaijan",
    "dial_code": "+994",
    "code": "AZ"
    },
    {
    "name": "Bahamas",
    "dial_code": "+1242",
    "code": "BS"
    },
    {
    "name": "Bahrain",
    "dial_code": "+973",
    "code": "BH"
    },
    {
    "name": "Bangladesh",
    "dial_code": "+880",
    "code": "BD"
    },
    {
    "name": "Barbados",
    "dial_code": "+1246",
    "code": "BB"
    },
    {
    "name": "Belarus",
    "dial_code": "+375",
    "code": "BY"
    },
    {
    "name": "Belgium",
    "dial_code": "+32",
    "code": "BE"
    },
    {
    "name": "Belize",
    "dial_code": "+501",
    "code": "BZ"
    },
    {
    "name": "Benin",
    "dial_code": "+229",
    "code": "BJ"
    },
    {
    "name": "Bermuda",
    "dial_code": "+1441",
    "code": "BM"
    },
    {
    "name": "Bhutan",
    "dial_code": "+975",
    "code": "BT"
    },
    {
    "name": "Bolivia, Plurinational State of",
    "dial_code": "+591",
    "code": "BO"
    },
    {
    "name": "Bosnia and Herzegovina",
    "dial_code": "+387",
    "code": "BA"
    },
    {
    "name": "Botswana",
    "dial_code": "+267",
    "code": "BW"
    },
    {
    "name": "Brazil",
    "dial_code": "+55",
    "code": "BR"
    },
    {
    "name": "British Indian Ocean Territory",
    "dial_code": "+246",
    "code": "IO"
    },
    {
    "name": "Brunei Darussalam",
    "dial_code": "+673",
    "code": "BN"
    },
    {
    "name": "Bulgaria",
    "dial_code": "+359",
    "code": "BG"
    },
    {
    "name": "Burkina Faso",
    "dial_code": "+226",
    "code": "BF"
    },
    {
    "name": "Burundi",
    "dial_code": "+257",
    "code": "BI"
    },
    {
    "name": "Cambodia",
    "dial_code": "+855",
    "code": "KH"
    },
    {
    "name": "Cameroon",
    "dial_code": "+237",
    "code": "CM"
    },
    {
    "name": "Canada",
    "dial_code": "+1",
    "code": "CA"
    },
    {
    "name": "Cape Verde",
    "dial_code": "+238",
    "code": "CV"
    },
    {
    "name": "Cayman Islands",
    "dial_code": "+ 345",
    "code": "KY"
    },
    {
    "name": "Central African Republic",
    "dial_code": "+236",
    "code": "CF"
    },
    {
    "name": "Chad",
    "dial_code": "+235",
    "code": "TD"
    },
    {
    "name": "Chile",
    "dial_code": "+56",
    "code": "CL"
    },
    {
    "name": "China",
    "dial_code": "+86",
    "code": "CN"
    },
    {
    "name": "Christmas Island",
    "dial_code": "+61",
    "code": "CX"
    },
    {
    "name": "Cocos (Keeling) Islands",
    "dial_code": "+61",
    "code": "CC"
    },
    {
    "name": "Colombia",
    "dial_code": "+57",
    "code": "CO"
    },
    {
    "name": "Comoros",
    "dial_code": "+269",
    "code": "KM"
    },
    {
    "name": "Congo",
    "dial_code": "+242",
    "code": "CG"
    },
    {
    "name": "Congo, The Democratic Republic of the Congo",
    "dial_code": "+243",
    "code": "CD"
    },
    {
    "name": "Cook Islands",
    "dial_code": "+682",
    "code": "CK"
    },
    {
    "name": "Costa Rica",
    "dial_code": "+506",
    "code": "CR"
    },
    {
    "name": "Cote d'Ivoire",
    "dial_code": "+225",
    "code": "CI"
    },
    {
    "name": "Croatia",
    "dial_code": "+385",
    "code": "HR"
    },
    {
    "name": "Cuba",
    "dial_code": "+53",
    "code": "CU"
    },
    {
    "name": "Cyprus",
    "dial_code": "+357",
    "code": "CY"
    },
    {
    "name": "Czech Republic",
    "dial_code": "+420",
    "code": "CZ"
    },
    {
    "name": "Denmark",
    "dial_code": "+45",
    "code": "DK"
    },
    {
    "name": "Djibouti",
    "dial_code": "+253",
    "code": "DJ"
    },
    {
    "name": "Dominica",
    "dial_code": "+1767",
    "code": "DM"
    },
    {
    "name": "Dominican Republic",
    "dial_code": "+1849",
    "code": "DO"
    },
    {
    "name": "Ecuador",
    "dial_code": "+593",
    "code": "EC"
    },
    {
    "name": "Egypt",
    "dial_code": "+20",
    "code": "EG"
    },
    {
    "name": "El Salvador",
    "dial_code": "+503",
    "code": "SV"
    },
    {
    "name": "Equatorial Guinea",
    "dial_code": "+240",
    "code": "GQ"
    },
    {
    "name": "Eritrea",
    "dial_code": "+291",
    "code": "ER"
    },
    {
    "name": "Estonia",
    "dial_code": "+372",
    "code": "EE"
    },
    {
    "name": "Ethiopia",
    "dial_code": "+251",
    "code": "ET"
    },
    {
    "name": "Falkland Islands (Malvinas)",
    "dial_code": "+500",
    "code": "FK"
    },
    {
    "name": "Faroe Islands",
    "dial_code": "+298",
    "code": "FO"
    },
    {
    "name": "Fiji",
    "dial_code": "+679",
    "code": "FJ"
    },
    {
    "name": "Finland",
    "dial_code": "+358",
    "code": "FI"
    },
    {
    "name": "France",
    "dial_code": "+33",
    "code": "FR"
    },
    {
    "name": "French Guiana",
    "dial_code": "+594",
    "code": "GF"
    },
    {
    "name": "French Polynesia",
    "dial_code": "+689",
    "code": "PF"
    },
    {
    "name": "Gabon",
    "dial_code": "+241",
    "code": "GA"
    },
    {
    "name": "Gambia",
    "dial_code": "+220",
    "code": "GM"
    },
    {
    "name": "Georgia",
    "dial_code": "+995",
    "code": "GE"
    },
    {
    "name": "Germany",
    "dial_code": "+49",
    "code": "DE"
    },
    {
    "name": "Ghana",
    "dial_code": "+233",
    "code": "GH"
    },
    {
    "name": "Gibraltar",
    "dial_code": "+350",
    "code": "GI"
    },
    {
    "name": "Greece",
    "dial_code": "+30",
    "code": "GR"
    },
    {
    "name": "Greenland",
    "dial_code": "+299",
    "code": "GL"
    },
    {
    "name": "Grenada",
    "dial_code": "+1473",
    "code": "GD"
    },
    {
    "name": "Guadeloupe",
    "dial_code": "+590",
    "code": "GP"
    },
    {
    "name": "Guam",
    "dial_code": "+1671",
    "code": "GU"
    },
    {
    "name": "Guatemala",
    "dial_code": "+502",
    "code": "GT"
    },
    {
    "name": "Guernsey",
    "dial_code": "+44",
    "code": "GG"
    },
    {
    "name": "Guinea",
    "dial_code": "+224",
    "code": "GN"
    },
    {
    "name": "Guinea-Bissau",
    "dial_code": "+245",
    "code": "GW"
    },
    {
    "name": "Guyana",
    "dial_code": "+595",
    "code": "GY"
    },
    {
    "name": "Haiti",
    "dial_code": "+509",
    "code": "HT"
    },
    {
    "name": "Holy See (Vatican City State)",
    "dial_code": "+379",
    "code": "VA"
    },
    {
    "name": "Honduras",
    "dial_code": "+504",
    "code": "HN"
    },
    {
    "name": "Hong Kong",
    "dial_code": "+852",
    "code": "HK"
    },
    {
    "name": "Hungary",
    "dial_code": "+36",
    "code": "HU"
    },
    {
    "name": "Iceland",
    "dial_code": "+354",
    "code": "IS"
    },
    {
    "name": "India",
    "dial_code": "+91",
    "code": "IN"
    },
    {
    "name": "Indonesia",
    "dial_code": "+62",
    "code": "ID"
    },
    {
    "name": "Iran, Islamic Republic of Persian Gulf",
    "dial_code": "+98",
    "code": "IR"
    },
    {
    "name": "Iraq",
    "dial_code": "+964",
    "code": "IQ"
    },
    {
    "name": "Ireland",
    "dial_code": "+353",
    "code": "IE"
    },
    {
    "name": "Isle of Man",
    "dial_code": "+44",
    "code": "IM"
    },
    {
    "name": "Israel",
    "dial_code": "+972",
    "code": "IL"
    },
    {
    "name": "Italy",
    "dial_code": "+39",
    "code": "IT"
    },
    {
    "name": "Jamaica",
    "dial_code": "+1876",
    "code": "JM"
    },
    {
    "name": "Japan",
    "dial_code": "+81",
    "code": "JP"
    },
    {
    "name": "Jersey",
    "dial_code": "+44",
    "code": "JE"
    },
    {
    "name": "Jordan",
    "dial_code": "+962",
    "code": "JO"
    },
    {
    "name": "Kazakhstan",
    "dial_code": "+77",
    "code": "KZ"
    },
    {
    "name": "Kenya",
    "dial_code": "+254",
    "code": "KE"
    },
    {
    "name": "Kiribati",
    "dial_code": "+686",
    "code": "KI"
    },
    {
    "name": "Korea, Democratic People's Republic of Korea",
    "dial_code": "+850",
    "code": "KP"
    },
    {
    "name": "Korea, Republic of South Korea",
    "dial_code": "+82",
    "code": "KR"
    },
    {
    "name": "Kuwait",
    "dial_code": "+965",
    "code": "KW"
    },
    {
    "name": "Kyrgyzstan",
    "dial_code": "+996",
    "code": "KG"
    },
    {
    "name": "Laos",
    "dial_code": "+856",
    "code": "LA"
    },
    {
    "name": "Latvia",
    "dial_code": "+371",
    "code": "LV"
    },
    {
    "name": "Lebanon",
    "dial_code": "+961",
    "code": "LB"
    },
    {
    "name": "Lesotho",
    "dial_code": "+266",
    "code": "LS"
    },
    {
    "name": "Liberia",
    "dial_code": "+231",
    "code": "LR"
    },
    {
    "name": "Libyan Arab Jamahiriya",
    "dial_code": "+218",
    "code": "LY"
    },
    {
    "name": "Liechtenstein",
    "dial_code": "+423",
    "code": "LI"
    },
    {
    "name": "Lithuania",
    "dial_code": "+370",
    "code": "LT"
    },
    {
    "name": "Luxembourg",
    "dial_code": "+352",
    "code": "LU"
    },
    {
    "name": "Macao",
    "dial_code": "+853",
    "code": "MO"
    },
    {
    "name": "Macedonia",
    "dial_code": "+389",
    "code": "MK"
    },
    {
    "name": "Madagascar",
    "dial_code": "+261",
    "code": "MG"
    },
    {
    "name": "Malawi",
    "dial_code": "+265",
    "code": "MW"
    },
    {
    "name": "Malaysia",
    "dial_code": "+60",
    "code": "MY"
    },
    {
    "name": "Maldives",
    "dial_code": "+960",
    "code": "MV"
    },
    {
    "name": "Mali",
    "dial_code": "+223",
    "code": "ML"
    },
    {
    "name": "Malta",
    "dial_code": "+356",
    "code": "MT"
    },
    {
    "name": "Marshall Islands",
    "dial_code": "+692",
    "code": "MH"
    },
    {
    "name": "Martinique",
    "dial_code": "+596",
    "code": "MQ"
    },
    {
    "name": "Mauritania",
    "dial_code": "+222",
    "code": "MR"
    },
    {
    "name": "Mauritius",
    "dial_code": "+230",
    "code": "MU"
    },
    {
    "name": "Mayotte",
    "dial_code": "+262",
    "code": "YT"
    },
    {
    "name": "Mexico",
    "dial_code": "+52",
    "code": "MX"
    },
    {
    "name": "Micronesia, Federated States of Micronesia",
    "dial_code": "+691",
    "code": "FM"
    },
    {
    "name": "Moldova",
    "dial_code": "+373",
    "code": "MD"
    },
    {
    "name": "Monaco",
    "dial_code": "+377",
    "code": "MC"
    },
    {
    "name": "Mongolia",
    "dial_code": "+976",
    "code": "MN"
    },
    {
    "name": "Montenegro",
    "dial_code": "+382",
    "code": "ME"
    },
    {
    "name": "Montserrat",
    "dial_code": "+1664",
    "code": "MS"
    },
    {
    "name": "Morocco",
    "dial_code": "+212",
    "code": "MA"
    },
    {
    "name": "Mozambique",
    "dial_code": "+258",
    "code": "MZ"
    },
    {
    "name": "Myanmar",
    "dial_code": "+95",
    "code": "MM"
    },
    {
    "name": "Namibia",
    "dial_code": "+264",
    "code": "NA"
    },
    {
    "name": "Nauru",
    "dial_code": "+674",
    "code": "NR"
    },
    {
    "name": "Nepal",
    "dial_code": "+977",
    "code": "NP"
    },
    {
    "name": "Netherlands",
    "dial_code": "+31",
    "code": "NL"
    },
    {
    "name": "Netherlands Antilles",
    "dial_code": "+599",
    "code": "AN"
    },
    {
    "name": "New Caledonia",
    "dial_code": "+687",
    "code": "NC"
    },
    {
    "name": "New Zealand",
    "dial_code": "+64",
    "code": "NZ"
    },
    {
    "name": "Nicaragua",
    "dial_code": "+505",
    "code": "NI"
    },
    {
    "name": "Niger",
    "dial_code": "+227",
    "code": "NE"
    },
    {
    "name": "Nigeria",
    "dial_code": "+234",
    "code": "NG"
    },
    {
    "name": "Niue",
    "dial_code": "+683",
    "code": "NU"
    },
    {
    "name": "Norfolk Island",
    "dial_code": "+672",
    "code": "NF"
    },
    {
    "name": "Northern Mariana Islands",
    "dial_code": "+1670",
    "code": "MP"
    },
    {
    "name": "Norway",
    "dial_code": "+47",
    "code": "NO"
    },
    {
    "name": "Oman",
    "dial_code": "+968",
    "code": "OM"
    },
    {
    "name": "Pakistan",
    "dial_code": "+92",
    "code": "PK"
    },
    {
    "name": "Palau",
    "dial_code": "+680",
    "code": "PW"
    },
    {
    "name": "Palestinian Territory, Occupied",
    "dial_code": "+970",
    "code": "PS"
    },
    {
    "name": "Panama",
    "dial_code": "+507",
    "code": "PA"
    },
    {
    "name": "Papua New Guinea",
    "dial_code": "+675",
    "code": "PG"
    },
    {
    "name": "Paraguay",
    "dial_code": "+595",
    "code": "PY"
    },
    {
    "name": "Peru",
    "dial_code": "+51",
    "code": "PE"
    },
    {
    "name": "Philippines",
    "dial_code": "+63",
    "code": "PH"
    },
    {
    "name": "Pitcairn",
    "dial_code": "+872",
    "code": "PN"
    },
    {
    "name": "Poland",
    "dial_code": "+48",
    "code": "PL"
    },
    {
    "name": "Portugal",
    "dial_code": "+351",
    "code": "PT"
    },
    {
    "name": "Puerto Rico",
    "dial_code": "+1939",
    "code": "PR"
    },
    {
    "name": "Qatar",
    "dial_code": "+974",
    "code": "QA"
    },
    {
    "name": "Romania",
    "dial_code": "+40",
    "code": "RO"
    },
    {
    "name": "Russia",
    "dial_code": "+7",
    "code": "RU"
    },
    {
    "name": "Rwanda",
    "dial_code": "+250",
    "code": "RW"
    },
    {
    "name": "Reunion",
    "dial_code": "+262",
    "code": "RE"
    },
    {
    "name": "Saint Barthelemy",
    "dial_code": "+590",
    "code": "BL"
    },
    {
    "name": "Saint Helena, Ascension and Tristan Da Cunha",
    "dial_code": "+290",
    "code": "SH"
    },
    {
    "name": "Saint Kitts and Nevis",
    "dial_code": "+1869",
    "code": "KN"
    },
    {
    "name": "Saint Lucia",
    "dial_code": "+1758",
    "code": "LC"
    },
    {
    "name": "Saint Martin",
    "dial_code": "+590",
    "code": "MF"
    },
    {
    "name": "Saint Pierre and Miquelon",
    "dial_code": "+508",
    "code": "PM"
    },
    {
    "name": "Saint Vincent and the Grenadines",
    "dial_code": "+1784",
    "code": "VC"
    },
    {
    "name": "Samoa",
    "dial_code": "+685",
    "code": "WS"
    },
    {
    "name": "San Marino",
    "dial_code": "+378",
    "code": "SM"
    },
    {
    "name": "Sao Tome and Principe",
    "dial_code": "+239",
    "code": "ST"
    },
    {
    "name": "Saudi Arabia",
    "dial_code": "+966",
    "code": "SA"
    },
    {
    "name": "Senegal",
    "dial_code": "+221",
    "code": "SN"
    },
    {
    "name": "Serbia",
    "dial_code": "+381",
    "code": "RS"
    },
    {
    "name": "Seychelles",
    "dial_code": "+248",
    "code": "SC"
    },
    {
    "name": "Sierra Leone",
    "dial_code": "+232",
    "code": "SL"
    },
    {
    "name": "Singapore",
    "dial_code": "+65",
    "code": "SG"
    },
    {
    "name": "Slovakia",
    "dial_code": "+421",
    "code": "SK"
    },
    {
    "name": "Slovenia",
    "dial_code": "+386",
    "code": "SI"
    },
    {
    "name": "Solomon Islands",
    "dial_code": "+677",
    "code": "SB"
    },
    {
    "name": "Somalia",
    "dial_code": "+252",
    "code": "SO"
    },
    {
    "name": "South Africa",
    "dial_code": "+27",
    "code": "ZA"
    },
    {
    "name": "South Sudan",
    "dial_code": "+211",
    "code": "SS"
    },
    {
    "name": "South Georgia and the South Sandwich Islands",
    "dial_code": "+500",
    "code": "GS"
    },
    {
    "name": "Spain",
    "dial_code": "+34",
    "code": "ES"
    },
    {
    "name": "Sri Lanka",
    "dial_code": "+94",
    "code": "LK"
    },
    {
    "name": "Sudan",
    "dial_code": "+249",
    "code": "SD"
    },
    {
    "name": "Suriname",
    "dial_code": "+597",
    "code": "SR"
    },
    {
    "name": "Svalbard and Jan Mayen",
    "dial_code": "+47",
    "code": "SJ"
    },
    {
    "name": "Swaziland",
    "dial_code": "+268",
    "code": "SZ"
    },
    {
    "name": "Sweden",
    "dial_code": "+46",
    "code": "SE"
    },
    {
    "name": "Switzerland",
    "dial_code": "+41",
    "code": "CH"
    },
    {
    "name": "Syrian Arab Republic",
    "dial_code": "+963",
    "code": "SY"
    },
    {
    "name": "Taiwan",
    "dial_code": "+886",
    "code": "TW"
    },
    {
    "name": "Tajikistan",
    "dial_code": "+992",
    "code": "TJ"
    },
    {
    "name": "Tanzania, United Republic of Tanzania",
    "dial_code": "+255",
    "code": "TZ"
    },
    {
    "name": "Thailand",
    "dial_code": "+66",
    "code": "TH"
    },
    {
    "name": "Timor-Leste",
    "dial_code": "+670",
    "code": "TL"
    },
    {
    "name": "Togo",
    "dial_code": "+228",
    "code": "TG"
    },
    {
    "name": "Tokelau",
    "dial_code": "+690",
    "code": "TK"
    },
    {
    "name": "Tonga",
    "dial_code": "+676",
    "code": "TO"
    },
    {
    "name": "Trinidad and Tobago",
    "dial_code": "+1868",
    "code": "TT"
    },
    {
    "name": "Tunisia",
    "dial_code": "+216",
    "code": "TN"
    },
    {
    "name": "Turkey",
    "dial_code": "+90",
    "code": "TR"
    },
    {
    "name": "Turkmenistan",
    "dial_code": "+993",
    "code": "TM"
    },
    {
    "name": "Turks and Caicos Islands",
    "dial_code": "+1649",
    "code": "TC"
    },
    {
    "name": "Tuvalu",
    "dial_code": "+688",
    "code": "TV"
    },
    {
    "name": "Uganda",
    "dial_code": "+256",
    "code": "UG"
    },
    {
    "name": "Ukraine",
    "dial_code": "+380",
    "code": "UA"
    },
    {
    "name": "United Arab Emirates",
    "dial_code": "+971",
    "code": "AE"
    },
    {
    "name": "United Kingdom",
    "dial_code": "+44",
    "code": "GB"
    },
    {
    "name": "United States",
    "dial_code": "+1",
    "code": "US"
    },
    {
    "name": "Uruguay",
    "dial_code": "+598",
    "code": "UY"
    },
    {
    "name": "Uzbekistan",
    "dial_code": "+998",
    "code": "UZ"
    },
    {
    "name": "Vanuatu",
    "dial_code": "+678",
    "code": "VU"
    },
    {
    "name": "Venezuela, Bolivarian Republic of Venezuela",
    "dial_code": "+58",
    "code": "VE"
    },
    {
    "name": "Vietnam",
    "dial_code": "+84",
    "code": "VN"
    },
    {
    "name": "Virgin Islands, British",
    "dial_code": "+1284",
    "code": "VG"
    },
    {
    "name": "Virgin Islands, U.S.",
    "dial_code": "+1340",
    "code": "VI"
    },
    {
    "name": "Wallis and Futuna",
    "dial_code": "+681",
    "code": "WF"
    },
    {
    "name": "Yemen",
    "dial_code": "+967",
    "code": "YE"
    },
    {
    "name": "Zambia",
    "dial_code": "+260",
    "code": "ZM"
    },
    {
    "name": "Zimbabwe",
    "dial_code": "+263",
    "code": "ZW"
    }
    ]
  const arabiccountryJsonList =  
  [
      {
          "code": "AD",
          "name": "أندورا",
          "dialCode": "+376"
      },
      {
          "code": "AE",
          "name": "الامارات العربية المتحدة",
          "dialCode": "+971"
      },
      {
          "code": "AF",
          "name": "أفغانستان",
          "dialCode": "+93"
      },
      {
          "code": "AG",
          "name": "أنتيجوا وبربودا",
          "dialCode": "+1"
      },
      {
          "code": "AI",
          "name": "أنجويلا",
          "dialCode": "+1"
      },
      {
          "code": "AL",
          "name": "ألبانيا",
          "dialCode": "+355"
      },
      {
          "code": "AM",
          "name": "أرمينيا",
          "dialCode": "+374"
      },
      {
          "code": "AO",
          "name": "أنجولا",
          "dialCode": "+244"
      },
      {
          "code": "AQ",
          "name": "القطب الجنوبي",
          "dialCode": "+672"
      },
      {
          "code": "AR",
          "name": "الأرجنتين",
          "dialCode": "+54"
      },
      {
          "code": "AS",
          "name": "ساموا الأمريكية",
          "dialCode": "+1"
      },
      {
          "code": "AT",
          "name": "النمسا",
          "dialCode": "+43"
      },
      {
          "code": "AU",
          "name": "أستراليا",
          "dialCode": "+61"
      },
      {
          "code": "AW",
          "name": "آروبا",
          "dialCode": "+297"
      },
      {
          "code": "AX",
          "name": "جزر أولان",
          "dialCode": "+358"
      },
      {
          "code": "AZ",
          "name": "أذربيجان",
          "dialCode": "+994"
      },
      {
          "code": "BA",
          "name": "البوسنة والهرسك",
          "dialCode": "+387"
      },
      {
          "code": "BB",
          "name": "بربادوس",
          "dialCode": "+1"
      },
      {
          "code": "BD",
          "name": "بنجلاديش",
          "dialCode": "+880"
      },
      {
          "code": "BE",
          "name": "بلجيكا",
          "dialCode": "+32"
      },
      {
          "code": "BF",
          "name": "بوركينا فاسو",
          "dialCode": "+226"
      },
      {
          "code": "BG",
          "name": "بلغاريا",
          "dialCode": "+359"
      },
      {
          "code": "BH",
          "name": "البحرين",
          "dialCode": "+973"
      },
      {
          "code": "BI",
          "name": "بوروندي",
          "dialCode": "+257"
      },
      {
          "code": "BJ",
          "name": "بنين",
          "dialCode": "+229"
      },
      {
          "code": "BL",
          "name": "سان بارتيلمي",
          "dialCode": "+590"
      },
      {
          "code": "BM",
          "name": "برمودا",
          "dialCode": "+1"
      },
      {
          "code": "BN",
          "name": "بروناي",
          "dialCode": "+673"
      },
      {
          "code": "BO",
          "name": "بوليفيا",
          "dialCode": "+591"
      },
      {
          "code": "BQ",
          "name": "بونير",
          "dialCode": "+599"
      },
      {
          "code": "BR",
          "name": "البرازيل",
          "dialCode": "+55"
      },
      {
          "code": "BS",
          "name": "الباهاما",
          "dialCode": "+1"
      },
      {
          "code": "BT",
          "name": "بوتان",
          "dialCode": "+975"
      },
      {
          "code": "BV",
          "name": "جزيرة بوفيه",
          "dialCode": "+47"
      },
      {
          "code": "BW",
          "name": "بتسوانا",
          "dialCode": "+267"
      },
      {
          "code": "BY",
          "name": "روسيا البيضاء",
          "dialCode": "+375"
      },
      {
          "code": "BZ",
          "name": "بليز",
          "dialCode": "+501"
      },
      {
          "code": "CA",
          "name": "كندا",
          "dialCode": "+1"
      },
      {
          "code": "CC",
          "name": "جزر كوكوس",
          "dialCode": "+61"
      },
      {
          "code": "CD",
          "name": "جمهورية الكونغو الديمقراطية",
          "dialCode": "+243"
      },
      {
          "code": "CF",
          "name": "جمهورية افريقيا الوسطى",
          "dialCode": "+236"
      },
      {
          "code": "CG",
          "name": "الكونغو - برازافيل",
          "dialCode": "+242"
      },
      {
          "code": "CH",
          "name": "سويسرا",
          "dialCode": "+41"
      },
      {
          "code": "CI",
          "name": "ساحل العاج",
          "dialCode": "+225"
      },
      {
          "code": "CK",
          "name": "جزر كوك",
          "dialCode": "+682"
      },
      {
          "code": "CL",
          "name": "شيلي",
          "dialCode": "+56"
      },
      {
          "code": "CM",
          "name": "الكاميرون",
          "dialCode": "+237"
      },
      {
          "code": "CN",
          "name": "الصين",
          "dialCode": "+86"
      },
      {
          "code": "CO",
          "name": "كولومبيا",
          "dialCode": "+57"
      },
      {
          "code": "CR",
          "name": "كوستاريكا",
          "dialCode": "+506"
      },
      {
          "code": "CU",
          "name": "كوبا",
          "dialCode": "+53"
      },
      {
          "code": "CV",
          "name": "الرأس الأخضر",
          "dialCode": "+238"
      },
      {
          "code": "CW",
          "name": "كوراساو",
          "dialCode": "+599"
      },
      {
          "code": "CX",
          "name": "جزيرة الكريسماس",
          "dialCode": "+61"
      },
      {
          "code": "CY",
          "name": "قبرص",
          "dialCode": "+357"
      },
      {
          "code": "CZ",
          "name": "جمهورية التشيك",
          "dialCode": "+420"
      },
      {
          "code": "DE",
          "name": "ألمانيا",
          "dialCode": "+49"
      },
      {
          "code": "DJ",
          "name": "جيبوتي",
          "dialCode": "+253"
      },
      {
          "code": "DK",
          "name": "الدانمرك",
          "dialCode": "+45"
      },
      {
          "code": "DM",
          "name": "دومينيكا",
          "dialCode": "+1"
      },
      {
          "code": "DO",
          "name": "جمهورية الدومينيك",
          "dialCode": "+1"
      },
      {
          "code": "DZ",
          "name": "الجزائر",
          "dialCode": "+213"
      },
      {
          "code": "EC",
          "name": "الاكوادور",
          "dialCode": "+593"
      },
      {
          "code": "EE",
          "name": "استونيا",
          "dialCode": "+372"
      },
      {
          "code": "EG",
          "name": "مصر",
          "dialCode": "+20"
      },
      {
          "code": "EH",
          "name": "الصحراء الغربية",
          "dialCode": "+212"
      },
      {
          "code": "ER",
          "name": "اريتريا",
          "dialCode": "+291"
      },
      {
          "code": "ES",
          "name": "أسبانيا",
          "dialCode": "+34"
      },
      {
          "code": "ET",
          "name": "اثيوبيا",
          "dialCode": "+251"
      },
      {
          "code": "FI",
          "name": "فنلندا",
          "dialCode": "+358"
      },
      {
          "code": "FJ",
          "name": "فيجي",
          "dialCode": "+679"
      },
      {
          "code": "FK",
          "name": "جزر فوكلاند",
          "dialCode": "+500"
      },
      {
          "code": "FM",
          "name": "ميكرونيزيا",
          "dialCode": "+691"
      },
      {
          "code": "FO",
          "name": "جزر فارو",
          "dialCode": "+298"
      },
      {
          "code": "FR",
          "name": "فرنسا",
          "dialCode": "+33"
      },
      {
          "code": "GA",
          "name": "الجابون",
          "dialCode": "+241"
      },
      {
          "code": "GB",
          "name": "المملكة المتحدة",
          "dialCode": "+44"
      },
      {
          "code": "GD",
          "name": "جرينادا",
          "dialCode": "+1"
      },
      {
          "code": "GE",
          "name": "جورجيا",
          "dialCode": "+995"
      },
      {
          "code": "GF",
          "name": "غويانا",
          "dialCode": "+594"
      },
      {
          "code": "GG",
          "name": "غيرنزي",
          "dialCode": "+44"
      },
      {
          "code": "GH",
          "name": "غانا",
          "dialCode": "+233"
      },
      {
          "code": "GI",
          "name": "جبل طارق",
          "dialCode": "+350"
      },
      {
          "code": "GL",
          "name": "جرينلاند",
          "dialCode": "+299"
      },
      {
          "code": "GM",
          "name": "غامبيا",
          "dialCode": "+220"
      },
      {
          "code": "GN",
          "name": "غينيا",
          "dialCode": "+224"
      },
      {
          "code": "GP",
          "name": "جوادلوب",
          "dialCode": "+590"
      },
      {
          "code": "GQ",
          "name": "غينيا الاستوائية",
          "dialCode": "+240"
      },
      {
          "code": "GR",
          "name": "اليونان",
          "dialCode": "+30"
      },
      {
          "code": "GS",
          "name": "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
          "dialCode": "+500"
      },
      {
          "code": "GT",
          "name": "جواتيمالا",
          "dialCode": "+502"
      },
      {
          "code": "GU",
          "name": "جوام",
          "dialCode": "+1"
      },
      {
          "code": "GW",
          "name": "غينيا بيساو",
          "dialCode": "+245"
      },
      {
          "code": "GY",
          "name": "غيانا",
          "dialCode": "+595"
      },
      {
          "code": "HK",
          "name": "هونج كونج الصينية",
          "dialCode": "+852"
      },
      {
          "code": "HM",
          "name": "جزيرة هيرد وماكدونالد",
          "dialCode": ""
      },
      {
          "code": "HN",
          "name": "هندوراس",
          "dialCode": "+504"
      },
      {
          "code": "HR",
          "name": "كرواتيا",
          "dialCode": "+385"
      },
      {
          "code": "HT",
          "name": "هايتي",
          "dialCode": "+509"
      },
      {
          "code": "HU",
          "name": "المجر",
          "dialCode": "+36"
      },
      {
          "code": "ID",
          "name": "اندونيسيا",
          "dialCode": "+62"
      },
      {
          "code": "IE",
          "name": "أيرلندا",
          "dialCode": "+353"
      },
      {
          "code": "IL",
          "name": "اسرائيل",
          "dialCode": "+972"
      },
      {
          "code": "IM",
          "name": "جزيرة مان",
          "dialCode": "+44"
      },
      {
          "code": "IN",
          "name": "الهند",
          "dialCode": "+91"
      },
      {
          "code": "IO",
          "name": "المحيط الهندي البريطاني",
          "dialCode": "+246"
      },
      {
          "code": "IQ",
          "name": "العراق",
          "dialCode": "+964"
      },
      {
          "code": "IR",
          "name": "ايران",
          "dialCode": "+98"
      },
      {
          "code": "IS",
          "name": "أيسلندا",
          "dialCode": "+354"
      },
      {
          "code": "IT",
          "name": "ايطاليا",
          "dialCode": "+39"
      },
      {
          "code": "JE",
          "name": "جيرسي",
          "dialCode": "+44"
      },
      {
          "code": "JM",
          "name": "جامايكا",
          "dialCode": "+1"
      },
      {
          "code": "JO",
          "name": "الأردن",
          "dialCode": "+962"
      },
      {
          "code": "JP",
          "name": "اليابان",
          "dialCode": "+81"
      },
      {
          "code": "KE",
          "name": "كينيا",
          "dialCode": "+254"
      },
      {
          "code": "KG",
          "name": "قرغيزستان",
          "dialCode": "+996"
      },
      {
          "code": "KH",
          "name": "كمبوديا",
          "dialCode": "+855"
      },
      {
          "code": "KI",
          "name": "كيريباتي",
          "dialCode": "+686"
      },
      {
          "code": "KM",
          "name": "جزر القمر",
          "dialCode": "+269"
      },
      {
          "code": "KN",
          "name": "سانت كيتس ونيفيس",
          "dialCode": "+1"
      },
      {
          "code": "KP",
          "name": "كوريا الشمالية",
          "dialCode": "+850"
      },
      {
          "code": "KR",
          "name": "كوريا الجنوبية",
          "dialCode": "+82"
      },
      {
          "code": "KW",
          "name": "الكويت",
          "dialCode": "+965"
      },
      {
          "code": "KY",
          "name": "جزر الكايمن",
          "dialCode": "+345"
      },
      {
          "code": "KZ",
          "name": "كازاخستان",
          "dialCode": "+7"
      },
      {
          "code": "LA",
          "name": "لاوس",
          "dialCode": "+856"
      },
      {
          "code": "LB",
          "name": "لبنان",
          "dialCode": "+961"
      },
      {
          "code": "LC",
          "name": "سانت لوسيا",
          "dialCode": "+1"
      },
      {
          "code": "LI",
          "name": "ليختنشتاين",
          "dialCode": "+423"
      },
      {
          "code": "LK",
          "name": "سريلانكا",
          "dialCode": "+94"
      },
      {
          "code": "LR",
          "name": "ليبيريا",
          "dialCode": "+231"
      },
      {
          "code": "LS",
          "name": "ليسوتو",
          "dialCode": "+266"
      },
      {
          "code": "LT",
          "name": "ليتوانيا",
          "dialCode": "+370"
      },
      {
          "code": "LU",
          "name": "لوكسمبورج",
          "dialCode": "+352"
      },
      {
          "code": "LV",
          "name": "لاتفيا",
          "dialCode": "+371"
      },
      {
          "code": "LY",
          "name": "ليبيا",
          "dialCode": "+218"
      },
      {
          "code": "MA",
          "name": "المغرب",
          "dialCode": "+212"
      },
      {
          "code": "MC",
          "name": "موناكو",
          "dialCode": "+377"
      },
      {
          "code": "MD",
          "name": "مولدافيا",
          "dialCode": "+373"
      },
      {
          "code": "ME",
          "name": "الجبل الأسود",
          "dialCode": "+382"
      },
      {
          "code": "MF",
          "name": "سانت مارتين",
          "dialCode": "+590"
      },
      {
          "code": "MG",
          "name": "مدغشقر",
          "dialCode": "+261"
      },
      {
          "code": "MH",
          "name": "جزر المارشال",
          "dialCode": "+692"
      },
      {
          "code": "MK",
          "name": "مقدونيا",
          "dialCode": "+389"
      },
      {
          "code": "ML",
          "name": "مالي",
          "dialCode": "+223"
      },
      {
          "code": "MM",
          "name": "ميانمار",
          "dialCode": "+95"
      },
      {
          "code": "MN",
          "name": "منغوليا",
          "dialCode": "+976"
      },
      {
          "code": "MO",
          "name": "ماكاو الصينية",
          "dialCode": "+853"
      },
      {
          "code": "MP",
          "name": "جزر ماريانا الشمالية",
          "dialCode": "+1"
      },
      {
          "code": "MQ",
          "name": "مارتينيك",
          "dialCode": "+596"
      },
      {
          "code": "MR",
          "name": "موريتانيا",
          "dialCode": "+222"
      },
      {
          "code": "MS",
          "name": "مونتسرات",
          "dialCode": "+1"
      },
      {
          "code": "MT",
          "name": "مالطا",
          "dialCode": "+356"
      },
      {
          "code": "MU",
          "name": "موريشيوس",
          "dialCode": "+230"
      },
      {
          "code": "MV",
          "name": "جزر الملديف",
          "dialCode": "+960"
      },
      {
          "code": "MW",
          "name": "ملاوي",
          "dialCode": "+265"
      },
      {
          "code": "MX",
          "name": "المكسيك",
          "dialCode": "+52"
      },
      {
          "code": "MY",
          "name": "ماليزيا",
          "dialCode": "+60"
      },
      {
          "code": "MZ",
          "name": "موزمبيق",
          "dialCode": "+258"
      },
      {
          "code": "NA",
          "name": "ناميبيا",
          "dialCode": "+264"
      },
      {
          "code": "NC",
          "name": "كاليدونيا الجديدة",
          "dialCode": "+687"
      },
      {
          "code": "NE",
          "name": "النيجر",
          "dialCode": "+227"
      },
      {
          "code": "NF",
          "name": "جزيرة نورفوك",
          "dialCode": "+672"
      },
      {
          "code": "NG",
          "name": "نيجيريا",
          "dialCode": "+234"
      },
      {
          "code": "NI",
          "name": "نيكاراجوا",
          "dialCode": "+505"
      },
      {
          "code": "NL",
          "name": "هولندا",
          "dialCode": "+31"
      },
      {
          "code": "NO",
          "name": "النرويج",
          "dialCode": "+47"
      },
      {
          "code": "NP",
          "name": "نيبال",
          "dialCode": "+977"
      },
      {
          "code": "NR",
          "name": "نورو",
          "dialCode": "+674"
      },
      {
          "code": "NU",
          "name": "نيوي",
          "dialCode": "+683"
      },
      {
          "code": "NZ",
          "name": "نيوزيلاندا",
          "dialCode": "+64"
      },
      {
          "code": "OM",
          "name": "عمان",
          "dialCode": "+968"
      },
      {
          "code": "PA",
          "name": "بنما",
          "dialCode": "+507"
      },
      {
          "code": "PE",
          "name": "بيرو",
          "dialCode": "+51"
      },
      {
          "code": "PF",
          "name": "بولينيزيا الفرنسية",
          "dialCode": "+689"
      },
      {
          "code": "PG",
          "name": "بابوا غينيا الجديدة",
          "dialCode": "+675"
      },
      {
          "code": "PH",
          "name": "الفيلبين",
          "dialCode": "+63"
      },
      {
          "code": "PK",
          "name": "باكستان",
          "dialCode": "+92"
      },
      {
          "code": "PL",
          "name": "بولندا",
          "dialCode": "+48"
      },
      {
          "code": "PM",
          "name": "سانت بيير وميكولون",
          "dialCode": "+508"
      },
      {
          "code": "PN",
          "name": "بتكايرن",
          "dialCode": "+872"
      },
      {
          "code": "PR",
          "name": "بورتوريكو",
          "dialCode": "+1"
      },
      {
          "code": "PS",
          "name": "فلسطين",
          "dialCode": "+970"
      },
      {
          "code": "PT",
          "name": "البرتغال",
          "dialCode": "+351"
      },
      {
          "code": "PW",
          "name": "بالاو",
          "dialCode": "+680"
      },
      {
          "code": "PY",
          "name": "باراجواي",
          "dialCode": "+595"
      },
      {
          "code": "QA",
          "name": "قطر",
          "dialCode": "+974"
      },
      {
          "code": "RE",
          "name": "روينيون",
          "dialCode": "+262"
      },
      {
          "code": "RO",
          "name": "رومانيا",
          "dialCode": "+40"
      },
      {
          "code": "RS",
          "name": "صربيا",
          "dialCode": "+381"
      },
      {
          "code": "RU",
          "name": "روسيا",
          "dialCode": "+7"
      },
      {
          "code": "RW",
          "name": "رواندا",
          "dialCode": "+250"
      },
      {
          "code": "SA",
          "name": "المملكة العربية السعودية",
          "dialCode": "+966"
      },
      {
          "code": "SB",
          "name": "جزر سليمان",
          "dialCode": "+677"
      },
      {
          "code": "SC",
          "name": "سيشل",
          "dialCode": "+248"
      },
      {
          "code": "SD",
          "name": "السودان",
          "dialCode": "+249"
      },
      {
          "code": "SE",
          "name": "السويد",
          "dialCode": "+46"
      },
      {
          "code": "SG",
          "name": "سنغافورة",
          "dialCode": "+65"
      },
      {
          "code": "SH",
          "name": "سانت هيلنا",
          "dialCode": "+290"
      },
      {
          "code": "SI",
          "name": "سلوفينيا",
          "dialCode": "+386"
      },
      {
          "code": "SJ",
          "name": "سفالبارد وجان مايان",
          "dialCode": "+47"
      },
      {
          "code": "SK",
          "name": "سلوفاكيا",
          "dialCode": "+421"
      },
      {
          "code": "SL",
          "name": "سيراليون",
          "dialCode": "+232"
      },
      {
          "code": "SM",
          "name": "سان مارينو",
          "dialCode": "+378"
      },
      {
          "code": "SN",
          "name": "السنغال",
          "dialCode": "+221"
      },
      {
          "code": "SO",
          "name": "الصومال",
          "dialCode": "+252"
      },
      {
          "code": "SR",
          "name": "سورينام",
          "dialCode": "+597"
      },
      {
          "code": "SS",
          "name": "جنوب السودان",
          "dialCode": "+211"
      },
      {
          "code": "ST",
          "name": "ساو تومي وبرينسيبي",
          "dialCode": "+239"
      },
      {
          "code": "SV",
          "name": "السلفادور",
          "dialCode": "+503"
      },
      {
          "code": "SX",
          "name": "سينت مارتن",
          "dialCode": "+1"
      },
      {
          "code": "SY",
          "name": "سوريا",
          "dialCode": "+963"
      },
      {
          "code": "SZ",
          "name": "سوازيلاند",
          "dialCode": "+268"
      },
      {
          "code": "TC",
          "name": "جزر الترك وجايكوس",
          "dialCode": "+1"
      },
      {
          "code": "TD",
          "name": "تشاد",
          "dialCode": "+235"
      },
      {
          "code": "TF",
          "name": "المقاطعات الجنوبية الفرنسية",
          "dialCode": "+262"
      },
      {
          "code": "TG",
          "name": "توجو",
          "dialCode": "+228"
      },
      {
          "code": "TH",
          "name": "تايلند",
          "dialCode": "+66"
      },
      {
          "code": "TJ",
          "name": "طاجكستان",
          "dialCode": "+992"
      },
      {
          "code": "TK",
          "name": "توكيلو",
          "dialCode": "+690"
      },
      {
          "code": "TL",
          "name": "تيمور الشرقية",
          "dialCode": "+670"
      },
      {
          "code": "TM",
          "name": "تركمانستان",
          "dialCode": "+993"
      },
      {
          "code": "TN",
          "name": "تونس",
          "dialCode": "+216"
      },
      {
          "code": "TO",
          "name": "تونجا",
          "dialCode": "+676"
      },
      {
          "code": "TR",
          "name": "تركيا",
          "dialCode": "+90"
      },
      {
          "code": "TT",
          "name": "ترينيداد وتوباغو",
          "dialCode": "+1"
      },
      {
          "code": "TV",
          "name": "توفالو",
          "dialCode": "+688"
      },
      {
          "code": "TW",
          "name": "تايوان",
          "dialCode": "+886"
      },
      {
          "code": "TZ",
          "name": "تانزانيا",
          "dialCode": "+255"
      },
      {
          "code": "UA",
          "name": "أوكرانيا",
          "dialCode": "+380"
      },
      {
          "code": "UG",
          "name": "أوغندا",
          "dialCode": "+256"
      },
      {
          "code": "UM",
          "name": "جزر الولايات المتحدة البعيدة الصغيرة",
          "dialCode": ""
      },
      {
          "code": "US",
          "name": "الولايات المتحدة الأمريكية",
          "dialCode": "+1"
      },
      {
          "code": "UY",
          "name": "أورجواي",
          "dialCode": "+598"
      },
      {
          "code": "UZ",
          "name": "أوزبكستان",
          "dialCode": "+998"
      },
      {
          "code": "VA",
          "name": "الفاتيكان",
          "dialCode": "+379"
      },
      {
          "code": "VC",
          "name": "سانت فنسنت وغرنادين",
          "dialCode": "+1"
      },
      {
          "code": "VE",
          "name": "فنزويلا",
          "dialCode": "+58"
      },
      {
          "code": "VG",
          "name": "جزر فرجين البريطانية",
          "dialCode": "+1"
      },
      {
          "code": "VI",
          "name": "جزر فرجين الأمريكية",
          "dialCode": "+1"
      },
      {
          "code": "VN",
          "name": "فيتنام",
          "dialCode": "+84"
      },
      {
          "code": "VU",
          "name": "فانواتو",
          "dialCode": "+678"
      },
      {
          "code": "WF",
          "name": "جزر والس وفوتونا",
          "dialCode": "+681"
      },
      {
          "code": "WS",
          "name": "ساموا",
          "dialCode": "+685"
      },
      {
          "code": "XK",
          "name": "كوسوفو",
          "dialCode": "+383"
      },
      {
          "code": "YE",
          "name": "اليمن",
          "dialCode": "+967"
      },
      {
          "code": "YT",
          "name": "مايوت",
          "dialCode": "+262"
      },
      {
          "code": "ZA",
          "name": "جمهورية جنوب افريقيا",
          "dialCode": "+27"
      },
      {
          "code": "ZM",
          "name": "زامبيا",
          "dialCode": "+260"
      },
      {
          "code": "ZW",
          "name": "زيمبابوي",
          "dialCode": "+263"
      }
  ]
  let language="en";
  if(language==lang)
{
  if(countrycode!=null || countrycode!= undefined){
    const result = countryJsonList.find(({ code }) => code === countrycode);
 
    if(result){
      return result.name

    }
  }

}else{
  if(countrycode!=null || countrycode!= undefined){
    const result = arabiccountryJsonList.find(({ code }) => code === countrycode);
 
    if(result){
      return result.name

    }
  }
}
if(countrycode!=null || countrycode!= undefined){
  if(language==lang){
    const result = countryJsonList.find(({ name }) => name === countrycode);
    if(result){

      return result.dial_code
  
    }
  }
  else{
    const result = arabiccountryJsonList.find(({ name }) => name === countrycode);
    if(result){

      return result.dialCode
  
    }
  }

//  console.log("coming to this------- condition",result)

}
if(countrycode!=null || countrycode!= undefined){
  if(language==lang){
    const result = countryJsonList.find(({ dial_code }) => dial_code === countrycode);
    if(result){

      return result.name
  
    }
  }
  else{
    const result = arabiccountryJsonList.find(({ dialCode }) => dialCode === countrycode);
    if(result){

      return result.name
  
    }
  }

//  console.log("coming to this------- condition",result)

}
 
  // console.log("result-----",result)
  //  

}